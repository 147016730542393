<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col md="4" lg="4" xl="3" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper d-flex justify-center">
            <div class="d-flex align-center px-10 px-lg-12 flex-column justify-center flex-grow-1">
              <v-img
                contain
                max-width="453px"
                width="100%"
                class="flex-grow-0 mb-5"
                :src="require(`@/assets/images/login/login_illustration_experts.svg`)"
              ></v-img>
              <p class="text-center accent--text text-xl">bAV Portal</p>
            </div>
          </div>
        </v-col>

        <v-col md="8" lg="8" xl="9" cols="12" class="d-flex auth-bg-v2 pb-0 flex-column">
          <div class="d-flex flex-column">
            <AppBarI18n />
          </div>
          <div class="d-flex flex-grow-1 align-center">
            <v-card class="auth-card" color="transparent">
              <v-card-title class="d-flex align-center justify-center py-7">
                <v-img :src="appLogo" max-width="375px" alt="logo" contain></v-img>
              </v-card-title>
              <v-card-text v-if="!confirmed">
                <p class="font-weight-bold text--secondary mb-3 text-left text-xl">
                  {{ $t('forgotPwWelcome') }}
                </p>
                <p class="mb-0 text-sm">
                  {{ $t('forgotPwWelcomeMsg') }}
                </p>
              </v-card-text>
              <v-card-text v-else>
                <p class="font-weight-bold text--secondary mb-3 text-left text-xl">
                  {{ $t('forgotPwConfirm') }}
                </p>
                <p class="mb-0 text-sm">
                  {{ $t('forgotPwConfirmMsg') }}
                </p>
              </v-card-text>

              <!-- <v-card-text class="mb-0" v-if="showErrorMessage">
                <v-alert text color="error" class="mb-0">

                  {{ $t("errorPasswordForgottenInvalidEmail") }}

                </v-alert>
              </v-card-text> -->

              <!-- forget form -->
              <v-card-text v-if="!confirmed">
                <v-form ref="forgetPwForm" v-model="isFormValid" @submit.prevent="handleSubmit">
                  <v-text-field
                    ref="forgetPwEmailField"
                    v-model="email"
                    outlined
                    lazy-validation
                    :rules="[validators.required, validators.emailValidator]"
                    :label="$t('forgotPwEmail')"
                    :placeholder="$t('forgotPwEmail')"
                    hide-details="auto"
                    class="required"
                  >
                    <template #message="{ message }">
                      {{ $t(message) }}
                    </template>
                  </v-text-field>

                  <v-btn depressed block color="primary" type="submit" class="mt-3">
                    {{ $t('forgotPwReset') }}
                  </v-btn>
                  <v-btn block outlined color="primary" class="mt-3" @click="$router.go(-1)">
                    {{ $t('forgotPwCancel') }}
                  </v-btn>
                </v-form>
              </v-card-text>
              <v-card-text v-else>
                <v-form @submit.prevent>
                  <v-btn depressed block color="primary" class="mt-1" @click="$router.go(-1)">
                    {{ $t('auth2faBackToLogin') }}
                  </v-btn>
                </v-form>
              </v-card-text>

              <!-- support  -->

              <v-card-text class="text-center flex-wrap mt-3 mb-3">
                <span class="mb-2 text-sm"> {{ $t('loginProblem') }} </span><br />
                <span>
                  <button
                    :disabled="!acceptedCookies"
                    class="support-link primary--text text-sm mt-1"
                    @click.prevent="openUserlike"
                  >
                    {{ $t('loginSupportLink') }}
                  </button>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <Footer />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, watch, getCurrentInstance } from '@vue/composition-api';
import { required, emailValidator } from '@core/utils/validation';
import themeConfig from '@themeConfig';
import { forgotPassword } from '../../api/fusion-auth';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: {
    Footer,
    AppBarI18n,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const email = ref('');
    const confirmed = ref(false);
    const isFormValid = ref(false);
    const showErrorMessage = ref(false);
    const forgetPwForm = ref(null);
    const acceptedCookies = ref(vm.$store.getters.necessaryCookies);

    const handleSubmit = () => {
      showErrorMessage.value = false;

      const isFormValid = forgetPwForm.value.validate();
      if (!isFormValid) return;

      forgotPassword(email.value)
        .then(() => (confirmed.value = true))
        .catch(() => {
          showErrorMessage.value = true;
          email.value = '';
        });
    };
    const openUserlike = () => {
      vm.$userlike.userlikeStartChat();
    };

    watch(
      () => vm.$store.getters.necessaryCookies,
      () => {
        if (vm.$store.getters.necessaryCookies) {
          acceptedCookies.value = true;
        } else {
          acceptedCookies.value = false;
        }
      },
    );

    return {
      email,
      confirmed,
      isFormValid,
      forgetPwForm,
      showErrorMessage,
      handleSubmit,
      acceptedCookies,
      openUserlike,
      validators: {
        required,
        emailValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    };
  },
};
</script>

<style lang="scss" scoped></style>
